* {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  background-image: './';
}

@font-face {
  font-family: "Heavy";
  src: local("Liberator-Heavy"),
    url("./components/Fonts/Liberator/Liberator-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "Light";
  src: local("Liberator-Light"),
    url("./components/Fonts/Liberator/Liberator-Light.otf") format("opentype");
}

@font-face {
  font-family: "Medium";
  src: local("Liberator-Medium"),
    url("./components/Fonts/Liberator/Liberator-Medium.otf") format("opentype");
}

.headerpic {
  opacity: 0.7;
}

header {
  background-color: #022a3a;
  font-size: 16px;
  color: #e8b00f;
  padding-top: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  height: 90px;
  overflow: hidden;
  z-index: 1;
}

.header-title {
  margin: 5px;
  text-align: right;
  padding-right: 15px;
}

.content {
  margin-top: 100px;
}

.pg-name {
  font-family: "Heavy";
  font-size: 40px;
  line-height: .8em;
}

.pg-subname {
  font-family: "Light";
  font-size: 20px;
  padding-bottom: 8px;
}

.firstclass {
  padding-right: 10px;
  padding-left: 10px;
  float: right;
  height: 60px;
  width: 60px;
}

.menu-btn {
  margin-top: 20px;
  margin-left: 10px;
  color: #c6ccd0;
  z-index: 50px;

}

.nav {
  z-index: 50;
  position: fixed;
  top: 0;
}

.navlink {
  text-decoration: none;
  color: #022a3a;
  font-size: 19px;
  font-family: "Medium";
  list-style: none;
  width: 100%;
}

.navlink:hover {
  cursor: pointer;
}

.ntag-logo {
  padding: 15px;
}

.aboutpic {
  max-width: 400px;
}

.card {
  width: 80%;
  margin-top: 3%;
  margin-bottom: 10%;
  margin-left: 10%;
  margin-right: 10%;
  overflow: hidden;
  text-align: center;
}

.accomp-card {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 3%;
  margin-bottom: 10%;
  padding: 10px;
}

.accomp-grid {
  padding-top: 15px;
}

.subcard {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 80px;
}

.steps {
  padding-bottom: 15px;
}

.steps-mobile {
  overflow: hidden;
  height: 0;
  width: 0;
}

.chakra-stack {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-left: 3%;
  padding-right: 3%;
  /* margin-bottom: 8%; */
}

.gallerycard {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
}

.galleryimgcard {
  margin-bottom: 10px;
}

.galleryimg {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-bottom: 5px;
}

.gallery-name {
  font-family: "Medium";
  font-size: 18px;
  text-align: center;
}

.gallery-text {
  font-size: 14px;
  text-align: center;
  padding-bottom: 5px;
}

.gallery-year {
  font-family: "Light";
  font-size: 16px;
  text-align: center;
  padding-top: 2px;
  border-top: 1px solid #c6ccd0;
}

.apptcard,
.surveycard {
  border: 1px solid #e8b00f;
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 0px;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 15%;
  margin-bottom: 15%;
}

.appt,
.survey {
  margin-bottom: 2%;
}

.pgcard {
  margin-top: 12%;
}

.pgheader {
  font-family: "Light";
  font-size: 32px;
  padding-top: 10px;
  text-align: center;
}

.contact-pgheader,
.benefits-pgheader,
.resources-pgheader {
  font-family: "Light";
  font-size: 32px;
  padding-top: 20px;
  text-align: center;
}

.pg-subheading {
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
}

.name {
  font-family: "Medium";
  font-size: 24px;
  text-align: center;
}

.title {
  font-family: "Light";
  font-size: 20px;
}

.process-title {
  font-family: "Light";
  font-size: 20px;
  padding-top: 15px;
  text-align: left;
  padding-left: 20px;
}

.text {
  text-align: center;
  font-size: 14px;
}

.left-text {
  text-align: left;
  font-size: 14px;
}

.texttolink {
  text-align: center;
  font-size: 14px;
  padding: 6px;
}

.link {
  text-decoration: underline;
  color: #0076a9;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
  color: #e8b00f;
}

.icon {
  padding-left: 10px;
  padding-right: 10px;
  display: inline;
}

.icon {
  color: #0076a9;
}

.icon:hover {
  color: #e8b00f;
}

.backtotop-btn {
  position: fixed;
  bottom: 60px;
  right: 25px;
  cursor: pointer;
  border: none;
  color: gray;
}

.backtotop-btn:hover {
  color: black;
}

footer {
  font-size: 16px;
  color: #c6ccd0;
  padding: 10px;
  position: fixed;
  bottom: 0;
  background-color: #022a3a;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

footer p {
  font-family: "Light";
}

.ig-icon {
  margin-bottom: -31px;
  margin-left: 93%;
}

.linkedin-icon {
  margin-bottom: -25px;
  margin-left: 97%;
}

/* footer a:hover {
  font-weight: bold;
} */

@media only screen and (max-width: 60em) {

  .pgcard {
    margin-top: 20%
  }

  .surveycard,
  .apptcard {
    margin-top: 20%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .pg-name {
    padding-top: 3px;
    font-size: 22px;
  }

  .pg-subname {
    font-size: 14px;
  }

  .firstclass {
    height: 35px;
    width: 35px;
    padding-left: 5px;
    padding-right: 5px;
  }

  footer {
    text-align: left;
    font-size: 14px;
  }

  .ig-icon {
    margin-left: 80%;
  }

  .linkedin-icon {
    margin-left: 90%;
  }

  .steps {
    overflow: hidden;
    height: 0;
    width: 0;
    padding-bottom: 0px;
  }

  .steps-mobile {
    overflow: visible;
    height: 200px;
    margin-bottom: 15px;
  }

  .card,
  .accomp-card,
  .gallery-pg {
    margin-bottom: 20%;
  }

  header {
    height: 77px;
  }

  .header-title {
    padding-right: 5px;
  }

  .menu-icon-btn {
    size: 2rem;
  }

}

@media only screen and (min-width: 470px) and (max-width: 975px) {}